import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "デザイナーがブラックボックスを作らないためにできること",
  "date": "2019-02-22T06:17:24.000Z",
  "slug": "entry/2019/02/22/151724",
  "tags": ["medley"],
  "hero": "./2019_02_22.png",
  "heroAlt": "ブラックボックス"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190221/20190221150235.png",
        "alt": "f:id:medley_inc:20190221150235p:plain",
        "title": "f:id:medley_inc:20190221150235p:plain"
      }}></img></p>
    <p>{`こんにちは。昨年末に`}<a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`クリエイターズストーリー`}</a>{`が公開され顔面が白日のもとにさらされ『イケメンじゃなくね？イケメンつーか犯罪者顔じゃん！』と影口たたかれてないか不安な日々をおくる開発本部のガラスのハートことイケメン担当デザイナーの小山です。`}</p>
    <p>{`さて今回は先月末におこなった社内の勉強会の内容をご紹介させていただきます。`}</p>
    <p>{`デザインのプロセスは、時代とともに明らかになりつつあり、個人主導より参加型のデザインワークが主流になりつつあります。例えばサービスデザインはステークホルダーと課題を協議しながら解決策を見出していきます。視点は異なりますが、デザインスプリントも各参加者の視点や思考を総動員して短期でプロダクト（解決策）を作り出す手法です。`}</p>
    <p>{`関係者が参加したデザインプロセスにより出来たデザインを製品レベルへ品質を高めるため、デザイナーの手によってブラッシュアップされていきます。この段階から本格的にデザイナーの感覚由来の思考が組み込まれ、プロダクトが洗練されていきます。`}</p>
    <p>{`ですが、思考が組み込まれると、周囲とは別世界の領域として扱われている感覚になることがあります。どういう思考プロセスを経て、デザインされたのか周囲は触れることができません。それゆえに、周囲はデザインにおいての思考プロセスはデザイナー頼みになりやすく、作業依頼の最後の決まり文句として『いい感じに！』という言葉を掛けがちです。`}</p>
    <p>{`こうした周囲が踏み込みづらい距離感が生まれてしまうのは仕方のないことだと思っています。`}</p>
    <p>{`原因は、デザイナーの感覚由来の思考プロセスが共有されない状態＝ブラックボックス化にあります。またデザイナーが自発的にブラックボックスをオープンにして、周囲の目に留まる環境を用意できていない点も原因としてあります。`}</p>
    <p>{`目に留まる環境ではないため、周囲はアウトプットだけしか注目できず『いい感じに！』以外の言葉を掛けようがありません。`}</p>
    <p>{`デザイナーは、色や形を論理的な思考と感情的な思考の掛け合わせでアウトプットします。目では見えにくいプロセスに突入するので、周囲からは抽象的で曖昧な領域と思われがちです。`}</p>
    <p>{`今回は、これまでの経験の中で最もその『曖昧な領域と思われがち』だと感じている`}<strong parentName="p">{`余白`}</strong>{`について、デザイナーの視点でご紹介`}{`*`}{`し、ブラックボックスの中身を少しお見せしたいと思います。`}</p>
    <p>{`*`}{`個人的見解なので世のデザイナー全員に当てはまる訳ではないことをご理解いただければ幸いです。`}</p>
    <h1>{`余白について`}</h1>
    <p>{`みなさん、余白の言葉の意味はご存知ですか？　辞書では `}{` `}{`“文字などに書いた紙面の何も記されないままで残っている部分”と記されています。字面としては言い得ているのですが、デザイナー視点ではこれが全てではありません。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/k/keisukekoyama/20190221/20190221220602.png",
        "alt": "f:id:keisukekoyama:20190221220602p:plain",
        "title": "f:id:keisukekoyama:20190221220602p:plain"
      }}></img></p>
    <p>{`もちろん”何も記されていない”が余白の前提なのですが、なぜそれが必要か？が重要です。`}</p>
    <p>{`まずは余白の機能から説明します。余白には最も伝えたいメッセージを視覚的に浮き彫らせ、理解を促す効果があります。多種多様で氾濫した情報から希望したものを抜き出すプロセスを、見ている人に強いるのではなく、デザインが肩代わりします。`}</p>
    <p>{`昨今の日本のデザインは、見ている人の情報に対しての許容量が増え、膨大な量を詰め込んでも理解してもらえる場面が多々あります。`}</p>
    <p>{`ただスタミナが必要です。スポーツをするのと同じように情報に触れるだけで人はスタミナを消費します。そして、人間であればスタミナには限りがあります。`}</p>
    <p>{`デザインは、その限りあるスタミナを情報の探索に使うのではなく、本来の目的に沿った行動に運用できるような仕組みに整備します。その手段の１つとして余白があります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/k/keisukekoyama/20190221/20190221220641.png",
        "alt": "f:id:keisukekoyama:20190221220641p:plain",
        "title": "f:id:keisukekoyama:20190221220641p:plain"
      }}></img></p>
    <p><em parentName="p">{`photo by`}{` `}<a parentName="em" {...{
          "href": "https://unsplash.com/photos/csq3MV5pPEk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        }}>{`Guillermo Pérez`}</a></em></p>
    <p>{`余白は目的ではなく情報を届けるための１つ手段です。`}</p>
    <p>{`届けたい情報がどういう文脈であるべきなのか、そことセットで考えなくては余白や目的が破綻します。このプロセスが抜けおちた状態でプロジェクトが成功してしまうとブラックボックス化が進みます。`}</p>
    <p>{`そうすると周囲の参加が難しい状況になり、『なんだかわからないけど良いだろう…』という状況に陥ります。いつまで経ってもデザインの思考プロセスは理解されないままの状態が続くでしょう。そのために余白を意味あるものとして文脈が必要となります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/k/keisukekoyama/20190221/20190221220659.png",
        "alt": "f:id:keisukekoyama:20190221220659p:plain",
        "title": "f:id:keisukekoyama:20190221220659p:plain"
      }}></img><em parentName="p">{`Apple Store 表参道店 店内　 Photo by `}<a parentName="em" {...{
          "href": "https://www.flickr.com/photos/wongwt/"
        }}>{`wongwt`}</a></em></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/k/keisukekoyama/20190221/20190221220716.png",
        "alt": "f:id:keisukekoyama:20190221220716p:plain",
        "title": "f:id:keisukekoyama:20190221220716p:plain"
      }}></img></p>
    <p><em parentName="p">{`ヨドバシカメラの店内`}{` `}{` `}{` `}{`Photo by `}<a parentName="em" {...{
          "href": "https://www.flickr.com/photos/chesterbr/"
        }}>{`chesterbr`}</a></em></p>
    <p>{`文脈は明快であるべきです。その例として AppleStore とヨドバシカメラがあります。AppleStore は電化製品を扱う店舗の部類としては、延べ床面積に対しての商品数が極端に少ないお店。電化製品としてではなく、利用者の人生に大きな影響を与える重要なプロダクトになり得るものを提供しているプライドが伺えます。高級ブランドに近い売りかたですね。`}</p>
    <p>{`ヨドバシカメラは様々なメーカーの商品を一度に、そして大量に伝達するために、より多くの商品を陳列します。世の中には商品が多くあり、その全てをありのままに伝達しスタッフのアドバイスを受けながら最適解を導き出す戦略です。`}</p>
    <p>{`この２つの店舗の場合、それぞれ余白はどうあるべきなのかその機能や意味が見出せます。`}</p>
    <p>{`AppleStore は通路を広くとり、商品を最も強調させるために机以外のノイズを限りなく削ぎ落とし、空間デザインにおいても余白を有効活用しています。物理的な余白を意図的に作ることで、商品に人の視線や意識が集まりやすくなり、より魅力が伝わる設計がなされています。`}</p>
    <p>{`一方、ヨドバシカメラは様々なメーカーの商品を多く陳列することで横断して比較でき、自分にあった商品を見つけられる購入体験を実現します。そのために狭いスペースの中に如何に商品を詰め込められるかを念頭に設計しているように見えます。そこに余白はどこにもなく、情報の洪水が押し寄せているように感じられます。`}</p>
    <p>{`ですが、余白はしっかりあります。それは人の頭の中です。`}</p>
    <p>{`ヨドバシカメラは種別ごとに商品をしっかりまとめ、近しい情報であれば同じ見栄えのポップで整理し、遠くからでもどこにあるか商品がわかりやすいように陳列の技術を駆使し、来店する人のスタミナの消費量を軽減しています。`}</p>
    <p>{`情報の整理をすることで、探す労力を利用者に強いることなく、無意識のうちに理解できるスイッチが入るように設計しています。ヨドバシカメラの場合は物理的な余白をとるのではなく、意識下に余白を作り出している高度な設計をしています。`}</p>
    <p>{`文脈を設計し伝えるべき情報を効率良く届けるために、余白は様々な手段があります。２つの事例をあげましたが、ここには物理的な余白、そして意識的な余白があります。`}</p>
    <p>{`主に UI デザインする個人的な意見ですが、余白の真の意味は、利用する側の頭の中の情報を整理し、スタミナ消費を軽減し意思決定のアクションを促せる『余地』を作ることにあると思っています。`}</p>
    <p>{`と、自分なりの余白の考えをお話しました。普段の業務の中でデザイナーの立場として、こうした話をする機会はなかなかありません。しかし考え話すことでデザインに周囲が参加できる素地を作らなければ、いつまで経っても、「いい感じにつくって」の状態から逃れられません。`}</p>
    <p>{`デザイナーの役目として作ることは大前提。しかし、単に手を動かして作るだけでは意図を理解されにくいため、対話を通して思考プロセスを共有しないとその後の改善はその場限りになります。これはデザインに限りません。`}</p>
    <p>{`事業編成による人事異動や事業規模の拡大など大きな変化のなかでも作り続けれるようにするために、余すところなく言語化し共有することが大事だと思います。`}</p>
    <p>{`メドレーには凡事徹底９箇条という仕事の質を高める指標があり、全社員がそれを意識しています。その指標の１つに『ドキュメントドリブン』というものがあります。全てを記録し共有できる状態にし無駄な取り組みをしないというものです。またドキュメントドリブンやドキュメントそのものの意味は、次の人にバトンを渡すことでもあると思っています。`}</p>
    <p>{`言語化されたデザインの思考プロセスは、デザイナー以外にバトンとして次に繋ぐことで、本当にプロダクトが良いものになるのか、検証と改善を継続できるようにする道しるべにもなります。`}</p>
    <p>{`幸運にもブラックボックスを開けて周囲を巻き込む術はたくさんあります。冒頭の手法以外にもライブデザイニングという手法があります。これはデザイナーのデザイン業務や思考プロセスを公開しながら進めていくものです。詳しくは
`}<a parentName="p" {...{
        "href": "https://note.mu/norinity1103/n/naf3688df2ba0"
      }}>{`こちら`}</a>{`。`}</p>
    <p>{`弊社のデザイナーはプロダクト毎に、一人専任でデザインを任されています。それだけに重要な仕事を任される面白さはあるものの、デザイナーの感覚由来の思考プロセスで解決策が生まれやすい状況とも言えます。`}</p>
    <p>{`デザインはデザイナーだけのものではないので、チームならチームに組織なら組織に思考のプロセスを明らかにすることで、新しい発見や解決策が生み出せるかもしれません。その機会をデザイナーから作り出し、より良い事業推進に貢献できたらと思います。`}</p>
    <p>{`最後まで読んでいただきありがとうございます。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      